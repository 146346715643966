<template>
  <div>
    <transition name="fade" appear>
      <div
        v-if="showModal"
        class="
          animated
          fadeIn
          fixed
          top-0
          left-0
          right-0
          mx-auto
          z-50
          w-screen
          h-screen
          bottom-0
          bg-black bg-opacity-50
        "
      >
        <div class="modal-header">
          <transition name="scale-up" appear>
            <div
              class="
                animated
                fadeInUp
                text-center
                bg-white
                w-80
                mx-auto
                rounded-2xl
                fixed
                left-0
                right-0
                top-0
                bottom-0
                my-auto
                h-96
                shadow-xl
                p-5
                flex-auto
                justify-center
              "
            >
              <div
                class="
                  absolute
                  -right-4
                  -top-4
                  flex
                  items-center
                  rounded-full
                  w-8
                  h-8
                  shadow-md
                  hover:shadow-lg
                  bg-white
                "
                @click="showModal = false"
              >
                <img
                  class="mx-auto p-2"
                  src="https://img.icons8.com/ios-glyphs/24/000000/delete-sign.png"
                />
              </div>
              <div class="w-full mb-2 rounded-md mx-0 bg-orange-tint">
                <div class="inline-flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="animate-pulse w-4 h-4 text-red-400 mx-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#ff8303"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <p
                    class="
                      text-orange
                      font-bold
                      -ml-4
                      text-lg
                      py-2
                      truncate
                      w-60
                      capitalize
                    "
                  >
                    add card
                  </p>
                </div>
              </div>
              <div class="px-4 pb-3 max-w-lg border-none rounded-xl">
                <div class="flex flex-wrap justify-between">
                  <div class="w-64 h-20 text-left">
                    <p class="font-semibold">card number</p>
                    <input
                      @change="validateCN()"
                      class="
                        outline-none
                        shadow
                        transition
                        duration-500
                        ease-in-out
                        focus:ring-2
                        focus:ring-orange
                        focus:bg-orange-tint
                        focus:text-orange
                        focus:shadow-lg
                        py-2
                        px-3
                        w-full
                        rounded-full
                      "
                      inputmode="numeric"
                      v-model="card.CN"
                      type="text"
                      placeholder="**** **** **** ****"
                      :class="{
                        border: errors.CN,
                        'border-red-600': errors.CN,
                        'text-red-600': errors.CN,
                        'animate-wiggle': errors.CN,
                      }"
                    />
                    <br />
                    <span
                      class="absolute w-60 text-xs font-extralight text-red-600"
                      >{{ errors.CN }}</span
                    >
                  </div>
                  <!-- <div class="w-16 h-16 mt-2 text-left">
              <p class="font-semibold">ccv</p>
              <input
              placeholder="***"
                class="
                    outline-none
                    shadow
                  transition
                  duration-500
                  ease-in-out
                  focus:ring-2 focus:ring-orange
                  focus:bg-orange-tint
                  focus:text-orange
                  focus:shadow-lg
                  py-2
                  px-3
                  w-full
                  rounded-full
                "
                type="text"
                pattern="[0-9\s]{13,19}"
                v-model="card.ccv"
              />
            </div> -->
                  <div class="w-40 h-20 text-left">
                    <p class="font-semibold">name on card</p>
                    <input
                      @change="validateNoC()"
                      class="
                        outline-none
                        shadow
                        transition
                        duration-500
                        ease-in-out
                        focus:ring-2
                        focus:ring-orange
                        focus:bg-orange-tint
                        focus:text-orange
                        focus:shadow-lg
                        py-2
                        px-3
                        w-full
                        rounded-full
                      "
                      v-model="card.NoC"
                      :class="{
                        border: errors.NoC,
                        'border-red-600': errors.NoC,
                        'text-red-600': errors.NoC,
                        'animate-wiggle': errors.NoC,
                      }"
                    />
                    <br />
                    <span
                      class="absolute w-60 text-xs font-extralight text-red-600"
                      >{{ errors.NoC }}</span
                    >
                  </div>
                  <div class="w-44 h-16 text-left">
                    <p class="font-semibold">date of expiry</p>
                    <input
                      @change="validateDoE()"
                      class="
                        appearance-none
                        outline-none
                        shadow
                        transition
                        duration-500
                        ease-in-out
                        focus:ring-2
                        focus:ring-orange
                        focus:bg-orange-tint
                        focus:text-orange
                        focus:shadow-lg
                        py-2
                        px-3
                        w-full
                        rounded-full
                      "
                      placeholder="mm/yyyy"
                      type="month"
                      v-model="card.DoE"
                      :class="{
                        border: errors.Doe,
                        'border-red-600': errors.Doe,
                        'text-red-600': errors.Doe,
                        'animate-wiggle': errors.Doe,
                      }"
                    />
                    <br />
                    <span
                      class="absolute w-60 text-xs font-extralight text-red-600"
                      >{{ errors.DoE }}</span
                    >
                  </div>
                </div>
              </div>
              <button
                class="
                  py-2
                  mt-4
                  w-full
                  mx-0
                  relative
                  text-center
                  rounded-2xl  
                  font-bold
                  bg-orange
                  text-white
                "
                v-if="!this.errors.CN && !this.errors.NoC && !this.errors.DoE"
                @click="submitCard()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="24"
                  height="24"
                  viewBox="0 0 172 172"
                  style="fill: #000000"
                  class="m-0 w-5 h-5 absolute left-3"
                >
                  <g
                    fill="none"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0,172v-172h172v172z" fill="none"></path>
                    <g fill="#ffffff">
                      <path
                        d="M35.83333,21.5c-7.91917,0 -14.33333,6.41417 -14.33333,14.33333v100.33333c0,7.91917 6.41417,14.33333 14.33333,14.33333h64.5v-14.33333h-64.5v-71.66667h100.33333v35.83333h14.33333v-64.5c0,-7.91917 -6.41417,-14.33333 -14.33333,-14.33333zM35.83333,35.83333h100.33333v14.33333h-100.33333zM50.16667,78.83333v14.33333h14.33333v-14.33333zM78.83333,78.83333v14.33333h43v-14.33333zM50.16667,107.5v14.33333h14.33333v-14.33333zM78.83333,107.5v14.33333h43v-14.33333zM136.16667,114.66667v21.5h-21.5v14.33333h21.5v21.5h14.33333v-21.5h21.5v-14.33333h-21.5v-21.5z"
                      ></path>
                    </g>
                  </g>
                </svg>

                <p class="pl-3 my-0 py-0">confirm add card</p>
              </button>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { isValidCardNumber, required } from "../helpers/validations";

export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      showModal: this.show,
      card: {
        CN: "",
        NoC: this.$store.state.user.name,
        DoE: "",
      },
      errors: {
        CN: null,
        NoC: null,
        DoE: null,
      },
    };
  },
  methods: {
    ...mapActions(["add_card"]),
    validateCN() {
      let missing = required(this.card.CN);
      let invalid = isValidCardNumber(this.card.CN);
      this.errors.CN = missing ? missing : invalid ? invalid : null;
    },
    validateNoC() {
      let missing = required(this.card.NoC);
      this.errors.NoC = missing ? missing : null;
    },
    validateDoE() {
      let missing = required(this.card.DoE);
      this.errors.DoE = missing ? missing : null;
    },
    async submitCard() {
      this.validateCN();
      this.validateNoC();
      this.validateDoE();
      if (!this.errors.CN && !this.errors.Noc && !this.errors.DoE) {
        await this.add_card(this.card);
        this.showModal = false;
      }
    },
  },
};
</script>
