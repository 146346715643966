<template>
  <page-layout
    title="Finance"
    v-if="user"
    :linkDefault="`/${user.username}/dashboard`"
  >
    <div class="mx-6 lg:flex w-full justify-around">
      <div class="w-80">
        <h2 class="font-bold text-2xl py-4 capitalize">wallet</h2>
        <transition name="scale-up" appear>
          <finance-wallet
            :amount="user.wallet.amount"
            :increased="user.wallet.amount > user.wallet.initial_amount"
            :initial_amount="user.wallet.initial_amount"
          />
        </transition>
      </div>
      <!-- <div class="w-80 lg:ml-12">
        <teleport to="body">
          <add-card :show="add" :key="componentKey" />
        </teleport>
        <button
          class="
            h-10
            w-10
            flex
            items-center
            float-right
            mr-4
            mt-4
            rounded-full  
            bg-orange-tint
            shadow-md
          "
          @click="
            add = true;
            componentKey++;
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            class="mx-auto"
            width="24"
            height="24"
            viewBox="0 0 172 172"
            style="fill: #000000"
          >
            <g
              fill="none"
              fill-rule="nonzero"
              stroke="none"
              stroke-width="1"
              stroke-linecap="butt"
              stroke-linejoin="miter"
              stroke-miterlimit="10"
              stroke-dasharray=""
              stroke-dashoffset="0"
              font-family="none"
              font-weight="none"
              font-size="none"
              text-anchor="none"
              style="mix-blend-mode: normal"
            >
              <path d="M0,172v-172h172v172z" fill="none"></path>
              <g fill="#ff8303">
                <path
                  d="M143.33333,78.83333h-50.16667v-50.16667c0,-3.956 -3.21067,-7.16667 -7.16667,-7.16667c-3.956,0 -7.16667,3.21067 -7.16667,7.16667v50.16667h-50.16667c-3.956,0 -7.16667,3.21067 -7.16667,7.16667c0,3.956 3.21067,7.16667 7.16667,7.16667h50.16667v50.16667c0,3.956 3.21067,7.16667 7.16667,7.16667c3.956,0 7.16667,-3.21067 7.16667,-7.16667v-50.16667h50.16667c3.956,0 7.16667,-3.21067 7.16667,-7.16667c0,-3.956 -3.21067,-7.16667 -7.16667,-7.16667z"
                ></path>
              </g>
            </g>
          </svg>
        </button>
        <h2 class="font-bold text-2xl py-4">Cards</h2>
        <empty
          v-if="user.wallet.cards.length === 0"
          :text="'no cards added yet'"
          :icon="'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAC/0lEQVR4nO2avWsUQRTAf+92A4JGJIgQMH+ANqIgWikIIdiKRdJomc5oImhiLhzZC6YwH7a2Igb/AKsolkkKwdZShIhioSgEsnvP4j7c3Nfe7d3e3dzNr5qbmXvz5u2bnbfzBiwWi8VisQwqEv6hGc7iOBvABDDcHZWaYh9ki8BfkAwHcQS4xUJh8p+AkbaplzyjoA9IuQr+XBwBJQMUnvwI8JYgmJYMX9ukZGLo8tAVJLeD6BQQywCpUHkCwJTJA8jS4W6hOBpXhhsqDwMUJ6+eswdcjq1dc+xKOrhaZ9xSe7tJ1WnTJAZsYKxq4yamS2kXUM/JDxIEY6YsAfivt6QDiepbjUoPSDkvNMtYi3oZQ6UH9BeRcUK1d8BL4GeyenWMYpywUquDW14h6eBOsjp1jkbihHq7gPE0EifUNYB6zp56zo7pdfWoWAJlVAuETKyrSV8vgUaI8oA9INcHddGo52g/xgJR8xr4JWAN0G0Fus3AGyBqF+gZ1HNuA/eAS8Dx5v/vrnLGT8s0h+F6IwygnrsJOtOilEd8d13wH4Zre94A+SevM8ABovP4uS3J8K0pGcWPIvQuYJYByLs9iM7LYm4zjgBZOtxVzwE4Xd5mggEuAuDntgA0wzFS7lNEJwFtW2KkhzkBUHL7/OTvA9v55tYSI+Ztg6KTCO8kHYxLOhgH3hcOPGJhngHafERuwhI4ispr0FnNOtsoAtxA5VlccSZ4wB8AXSkca+X8J6iso5wHzoGsccpfjCvcBA/4CFxDU1OQW8+/7f05YiZDyzHBA54DoLKi2dRsyROaQLNDxbzij/K2isRI3BRTkuiyu4bobOuSZE3SR0NhEzwAWfLnEG4BH4C/sYSorBL4j2u32yOxwcQaoNsKdBtrgHqNvZTfs7lBmxtMhoHPDYZD4V/ASdNuiUURFeKHl0D+iKmPbomFPoL2a/UJ3RUO5gmc6wg3UeeLekmr1wG0sBJUXtXqUvIAWeAzElwA3gC/k9atQ+y3emBisVgsFoulf/kHgl5/pG8kR8QAAAAASUVORK5CYII='"
        />
        <transition-group name="scale-up" tag="ul" appear>
          <li v-for="(card, index) in user.wallet.cards" :key="index">
            <credit-card :card="card" />
          </li>
        </transition-group>
      </div> -->
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "../components/base/pageLayout.vue";
import CreditCard from "../components/CreditCard.vue";
import FinanceWallet from "../components/FinanceWallet.vue";
import { mapState } from "vuex";
import AddCard from "../components/addCard.vue";
import Empty from "../components/empty.vue";
export default {
  name: "Finance",
  components: { PageLayout, CreditCard, FinanceWallet, AddCard, Empty },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      add: false,
      componentKey: 0,
    };
  },
};
</script>