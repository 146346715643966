<template>
  <div>
    <div
      class="
        rounded-2xl
        shadow-xl
        mx-2
        py-4
        h-24
        mb-2
        -mt-2
        text-white
        max-w-md
        w-80
        bg-gradient-to-l
        from-orange
        to-white
      "
    >
      <span
        v-if="increased"
        class="text-green-400 float-right align-right -mt-12"
        >Earned {{ amount - initial_amount }} this week!</span
      >
      <button
        class="rounded-full   bg-orange w-14 mx-4 h-14 shadow-md hover:shadow-xl"
      >
        <img
          src="https://img.icons8.com/ios-glyphs/30/ffffff/plus-math.png"
          class="mx-auto"
        />
      </button>
      <div class="float-right mr-4 w-32 text-center">
        <p class="text-4xl font-medium text-right">{{ amount }}</p>
        <p class="text-2xl font-extralight -mt-2 text-right">EGP</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["amount", "increased", "initial_amount"],
};
</script>