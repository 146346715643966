<template>
  <div class="m-4 flex content-center">
    <div class="mx-auto">
      <img v-if="icon" :src="icon" alt="" class="w-24 h-24 my-2 mx-auto" />
      <p
        class="text-lg text-gray-500 font-extralight text-center mx-autot px-12"
      >
        {{ text }}
      </p>
      <button
        v-if="action"
        class="
          bg-orange
          flex
          text-center
          items-center
          justify-center
          py-2
          mx-auto
          my-1
          px-6
          rounded-full  
        "
        @click="() => router.push(action.link)"
      >
        <img
          v-if="action.icon"
          :src="action.icon"
          alt=""
          class="w-6 h-6 my-2 mx-3"
        />
        <p class="text-white font-bold capitalize">{{ action.text }}</p>
      </button>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: "nothing to see here :(",
    },
    action: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const router = useRouter();
    return { router };
  },
};
</script>