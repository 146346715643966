<template>
  <div class="mx-auto relative mt-8 mb-12">
    <div
      v-if="!payment"
      class="
        transform
        bg-gray-500
        -rotate-12
        rounded-3xl
        w-80
        h-44
        absolute
        z-10
      "
    ></div>
    <div
      class="
        bg-gradient-to-r
        from-black
        to-gray-700
        w-80
        h-44
        rounded-3xl
        z-20
        relative
        shadow-2xl
      "
    >
      <div class="float-right mx-6 mt-8">
        <img
          class="w-10 float-right"
          src="https://img.icons8.com/external-vitaliy-gorbachev-fill-vitaly-gorbachev/60/ffffff/external-barcode-sales-vitaliy-gorbachev-fill-vitaly-gorbachev.png"
        />
        <br />
        <button
          v-if="!payment"
          class="
            py-2
            px-4
            rounded-2xl
             
            shadow-xl
            font-bold
            bg-blend-difference
            text-gray-500
          "
        >
          manage
        </button>
      </div>
      <p class="text-white text-3xl font-bold pt-8 mx-6 truncate max-w-sm">{{ card.NoC }}</p>
      <div class="text-white pt-8 mx-6">
        <img
          src="https://img.icons8.com/color/48/000000/mastercard-logo.png"
          class="absolute bottom-2 right-5"
        />
        <p class="text-xl">{{ card.DoE }}</p>
        <p class="text-2xl">**** **** **** {{ card.CN.substr(card.CN.length - 4) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payment: Boolean,
    card: Object,
  },
};
</script>